export const homeBgUrlList = reactive([
  "/images/home/bg_1.png",
  "/images/home/bg_2.png",
  "/images/home/bg_3.png",
  "/images/home/bg_4.png",
  "/images/home/bg_5.png",
  "/images/home/bg_6.png",
  "/images/home/bg_7.png",
  "/images/home/bg_8.png",
  "/images/home/bg_9.png",
]);
