<template>
  <div class="text-center p-2 pt-15 pb-10 op50">
    <p class="font-size-12px">
      <a
        class="underline"
        href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
      >
        CC BY-NC-SA 4.0
      </a>
      2021-PRESENT © shellingfordly
    </p>
    <div class="font-size-12px">
      <div class="i-carbon-user mr-1"></div>
      <span id="busuanzi_value_site_uv" class="mr-3">0</span>
      <div class="i-carbon-view mr-1"></div>
      <span id="busuanzi_value_site_pv">0</span>
    </div>
  </div>
</template>
